import AppBase from "../base/AppBase";
import {mainWritingContentStyle, notepadPageStyle, bottomStickyAreaStyle} from "../../assets/styles";
import {Input, Menu, MenuProps, Space} from "antd";
import {useEffect, useState} from "react";
import {get, set} from "lockr";
import {DownOutlined,DownloadOutlined} from "@ant-design/icons";
import {fontSizeList} from "../../constants/hardData";
import saveAsTxt from "../../utils/notepad";
import AdUnit from "../AdUnit";

export default function NotepadHome() {
    const [fontSize, setFontSize] = useState(18);
    const [textContent, setTextContent] = useState(get('TEXTPAD_CONTENT') || "");

    useEffect(() => {
        set('TEXTPAD_CONTENT', textContent)
    }, [textContent]);
    const onClick: MenuProps['onClick'] = (e) => {
        switch(e.key){
            case 'download':
                saveAsTxt(textContent)
                break;
            default:
                switch (e.keyPath[1]) {
                    case 'fontSize':
                        setFontSize(parseInt(e.key));
                        break;
                }
        }
    };
    return <AppBase noPadding={true}>
        {/*<AdUnit/>*/}
        <Menu theme="dark"
              style={{
                  background: '#000'
              }}
              onClick={onClick}
              selectedKeys={["a"]}
              mode="horizontal"
              items={[
                  {
                      label: <DownloadOutlined/> ,
                      key: 'download',
                  }, {
                      label: <>{fontSize}px <DownOutlined/></>,
                      key: 'fontSize',
                      // @ts-ignore
                      children: fontSizeList.map(val => {
                          return { label: `${val}px`, key: val }
                      })
                  }]}/>
        <div style={{...notepadPageStyle}}>
            <Input.TextArea style={{...mainWritingContentStyle(fontSize)}}
                            onChange={(e) => setTextContent(e.target.value)} value={textContent}/>
        </div>
        <div style={{...bottomStickyAreaStyle, position: "fixed"}}>
            <Space wrap={false} split={"|"}>
                <span>Character Count: {textContent.length}</span>
                <span>Word Count: {textContent.replaceAll("\n", ' ').split(' ').filter(function (n: string) {
                    return n !== ''
                }).length}</span>
            </Space>
        </div>
    </AppBase>
}