export const notepadPageStyle = {
    backgroundColor: 'rgba(238,211,17,0.41)',
    width: '100vw',
    paddingTop: 0,
    height: 'calc(100vh - 130px)'
}

export function mainWritingContentStyle(fontSize: number) {
    return {
        maxHeight: 'calc(100vh - 180px)',
        minHeight: 'calc(100vh - 180px)',
        height: '100%',
        width: '100%',
        paddingLeft: 50,
        borderTop: '2px solid black',
        border: 'none',
        lineHeight: `${fontSize+6}px`,
        paddingTop: `${fontSize+8}px`,
        fontSize: `${fontSize}px`,
        background: `repeating-linear-gradient(transparent, transparent ${fontSize+4}px,#9198e5 ${fontSize+5}px, #9198e5 ${fontSize+6}px) local`
    }
}

export const bottomStickyAreaStyle = {
    width: '100%',
    bottom: 0,
    background: 'white',
    borderTop: '1px solid #ccc',
    padding: '2px 8px',
}