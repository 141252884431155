import React from 'react';
import NotepadHome from "./components/notepad";
import {Layout} from "antd";
import TagManager from 'react-gtm-module'

function App() {
    const tagManagerArgs = {
        gtmId: 'G-TQ3RKX6TQ2'
    }

    TagManager.initialize(tagManagerArgs)
    return (
        <Layout>
            <NotepadHome/>
        </Layout>
    );
}

export default App;
