import React, {ReactNode} from 'react';
import {Layout, Typography} from 'antd';
import AppFooter from './footer/AppFooter';
import AppHeader from './header/AppHeader';

const {Text} = Typography;
const {Content} = Layout;

export default function AppBase({
                                    noHeader,
                                    loggedIn,
                                    noPadding,
                                    noBreadcrumb,
                                    children,
                                    ...props
                                }: {
    noHeader?: boolean | undefined,
    loggedIn?: boolean | undefined,
    noPadding?: boolean | undefined,
    noBreadcrumb?: boolean | undefined,
    children: ReactNode
}) {
    return (
        <Layout>
            {noHeader ? null : <AppHeader noBreadcrumb={noBreadcrumb}/>}
            <Layout
                className='site-layout'
                style={{
                    marginTop: 64,
                }}
            >
                <Layout>
                    <Content
                        className={"main-container"}
                        style={{
                            padding: noPadding ? 0 : 10,
                            height: `calc(100vh - 70px)`,
                            overflow: 'auto',
                        }}
                    >
                        <>
                            {children}
                            {/*<AppFooter/>*/}
                        </>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
}

