import React from 'react';
import {Avatar, Col, Dropdown, Layout, Menu, Row, Space, Typography} from 'antd';

const {Header} = Layout;
const {Title} = Typography;

export default function AppHeader(props: any) {

    const logOutUser = () => {
        props.navigate('/login', {replace: true});
        props.logoutUserAuthAction();
    };
    const onHandleLink = (event: any) => {
        let {history} = props;
        if (event.key === 'logout') {
            logOutUser();
        } else {
            history.push(event.key);
        }
    };

    let userMenu = (
        <Menu onClick={onHandleLink} theme={"dark"}>
            <Menu.Item key={'logout'}>
                Log Out
            </Menu.Item>
        </Menu>
    );

    return (
        <Header
            style={{
                background: 'rgb(17,17,17)',
                boxShadow: 'rgba(38, 50, 69, 0.2) 0px 2px 4px 0px',
                padding: '0 10px',
                position: 'fixed',
                zIndex: 1,
                width: '100%',
            }}
        >
            <Row justify={'space-between'}>
                <Col>
                    <Space>
                        <img
                        src={'/logo.png'}
                        alt='Mild Notepad'
                        style={{maxWidth: '100%', maxHeight: 62,  marginRight: 8}}
                    />
                    </Space>
                </Col>
            </Row>
        </Header>
    );

}

